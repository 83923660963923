import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import AppLoading from '../components/AppLoading';
import Header from '../components/Header';
import { useUser } from '../providers/UserProvider';
import { services } from '../services';

const Dashboard = () => {
  const { loading, setLoading, user, setUser } = useUser();
  const navigate = useNavigate();

  const checkUserAuthetication = async () => {
    if (!services.auth.hasToken) {
      navigate('/login');
      return;
    }

    const user = await services.auth.user();

    if (!user) {
      services.auth.removeToken();
      navigate('/login');
      return;
    }

    setUser(user);
    setLoading(false);
  };

  useEffect(() => {
    checkUserAuthetication();
  }, []);

  if (loading) {
    return <AppLoading />;
  }

  return (
    <>
      <Header />

      <main className="main">
        <Outlet />
      </main>
    </>
  );
};

export default Dashboard;

import { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { services } from '../services';

export const userContext = createContext();

export const useUser = () => {
  return useContext(userContext);
};

const UserProvider = ({ children }) => {
  const navigate = useNavigate();

  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [tree, setTree] = useState([
    {
      id: 1,
      title: 'מיון ואשפוז',
      slug: 'miun-ishpuz',
      icon: 'miun-ishpuz',
      is_last: false,
      reports: [
        {
          id: 10,
          img: 'imgurl',
          date: '14:23 12.04.2021',
          title: 'דוחות מיון שנתיים',
          iframe: 'http://iframeurl.com',
        },
        {
          id: 11,
          img: 'imgurl',
          date: '14:23 12.04.2021',
          title: 'דוחות מיון חודשיים',
          iframe: 'http://iframeurl.com',
        },
      ],
      children: [
        {
          id: 21,
          title: 'מיון',
          parent_id: 1,
          slug: 'miun',
          children: [
            {
              id: 31,
              title: 'מיון כללי',
              slug: 'general',
              reports: [
                {
                  id: 10,
                  img: 'imgurl',
                  date: '14:23 12.04.2021',
                  title: 'דוחות מיון שנתיים',
                  iframe: 'http://iframeurl.com',
                },
                {
                  id: 11,
                  img: 'imgurl',
                  date: '14:23 12.04.2021',
                  title: 'דוחות מיון חודשיים',
                  iframe: 'http://iframeurl.com',
                },
                {
                  id: 12,
                  img: 'imgurl',
                  date: '14:23 12.04.2021',
                  title: 'דוחות מיון חודשיים',
                  iframe: 'http://iframeurl.com',
                },
                {
                  id: 13,
                  img: 'imgurl',
                  date: '14:23 12.04.2021',
                  title: 'דוחות מיון חודשיים',
                  iframe: 'http://iframeurl.com',
                },
                {
                  id: 14,
                  img: 'imgurl',
                  date: '14:23 12.04.2021',
                  title: 'דוחות מיון חודשיים',
                  iframe: 'http://iframeurl.com',
                },
              ],
            },
            {
              id: 32,
              title: 'מיון אורטופדי',
              slug: 'orthopedic-miun',
              reports: [
                {
                  id: 15,
                  img: 'imgurl',
                  date: '14:23 12.04.2021',
                  title: 'דוחות מיון חודשיים',
                  iframe: 'http://iframeurl.com',
                },
              ],
            },
            {
              id: 33,
              title: 'מיון ילדים',
              slug: 'children-miun',
              reports: [
                {
                  id: 16,
                  img: 'imgurl',
                  date: '14:23 12.04.2021',
                  title: 'דוחות מיון חודשיים',
                  iframe: 'http://iframeurl.com',
                },
              ],
            },
          ],
        },
        {
          id: 32,
          title: 'חדרי ניתוח',
          parent_id: 1,
          children: [
            {
              title: 'ניתוח כללי',
              slug: 'operation-general',
              reports: [
                {
                  id: 17,
                  img: 'imgurl',
                  date: '14:23 12.04.2021',
                  title: 'דוחות מיון חודשיים',
                  iframe: 'http://iframeurl.com',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 2,
      title: 'אמבולטורי',
      icon: 'ambulatory',
      slug: 'ambulatori',
      children: [],
      is_last: true,
      reports: [
        {
          id: 18,
          img: 'imgurl',
          date: '14:23 12.04.2021',
          title: 'דוחות מיון חודשיים',
          iframe: 'http://iframeurl.com',
        },
      ],
    },
    {
      id: 3,
      title: 'חדרי ניתוח',
      icon: 'operation-room',
      slug: 'operation-room',
      children: [],
      is_last: true,
      reports: [
        {
          id: 19,
          img: 'imgurl',
          date: '14:23 12.04.2021',
          title: 'דוחות מיון חודשיים',
          iframe: 'http://iframeurl.com',
        },
      ],
    },
    {
      id: 4,
      title: `משא"ן ובריאות העובד`,
      icon: 'hr',
      slug: 'hr',
      children: [],
      is_last: true,
      reports: [
        {
          id: 20,
          img: 'imgurl',
          date: '14:23 12.04.2021',
          title: 'דוחות מיון יומייים',
          iframe: 'http://iframeurl.com',
        },
      ],
    },
    {
      id: 5,
      title: 'פיננסי לוגיסטי',
      icon: 'financial',
      slug: 'financial',
      children: [],
      is_last: true,
      reports: [
        {
          id: 21,
          img: 'imgurl',
          date: '14:23 12.04.2021',
          title: 'דוחות מיון יומיים',
          iframe: 'http://iframeurl.com',
        },
      ],
    },
    {
      id: 6,
      title: 'מעבדות',
      icon: 'labs',
      slug: 'labs',
      children: [],
      is_last: true,
      reports: [
        {
          id: 22,
          img: 'imgurl',
          date: '14:23 12.04.2021',
          title: 'דוחות מיון חודשיים',
          iframe: 'http://iframeurl.com',
        },
      ],
    },
    {
      id: 7,
      title: 'קליני',
      icon: 'clinics',
      slug: 'clinics',
      children: [],
      is_last: true,
      reports: [
        {
          id: 23,
          img: 'imgurl',
          date: '14:23 12.04.2021',
          title: 'דוחות מיון שבועיים',
          iframe: 'http://iframeurl.com',
        },
      ],
    },
    {
      id: 8,
      title: 'דוחות תפעוליים',
      icon: 'reports',
      slug: 'reports',
      children: [],
      is_last: true,
      reports: [
        {
          id: 24,
          img: 'imgurl',
          date: '14:23 12.04.2021',
          title: 'דוחות מיון חודשיים',
          iframe: 'http://iframeurl.com',
        },
      ],
    },
    {
      id: 9,
      title: 'מועדפים',
      icon: 'favorites',
      slug: 'favorites',
      children: [],
      is_last: true,
      reports: [],
    },
  ]);
  const [reports, setReports] = useState([
    {
      id: 10,
      img: 'imgurl',
      date: '14:23 12.04.2021',
      title: 'דוחות מיון שבועיים',
      iframe: 'http://iframeurl.com',
    },
    {
      id: 11,
      img: 'imgurl',
      date: '14:23 12.04.2021',
      title: 'דוחות מיון חודשיים',
      iframe: 'http://iframeurl.com',
    },
    {
      id: 12,
      img: 'imgurl',
      date: '14:23 12.04.2021',
      title: 'דוחות מיון חודשיים',
      iframe: 'http://iframeurl.com',
    },
    {
      id: 13,
      img: 'imgurl',
      date: '14:23 12.04.2021',
      title: 'דוחות מיון חודשיים',
      iframe: 'http://iframeurl.com',
    },
    {
      id: 14,
      img: 'imgurl',
      date: '14:23 12.04.2021',
      title: 'דוחות מיון יומיים',
      iframe: 'http://iframeurl.com',
    },
    {
      id: 15,
      img: 'imgurl',
      date: '14:23 12.04.2021',
      title: 'דוחות מיון יומיים',
      iframe: 'http://iframeurl.com',
    },
    {
      id: 16,
      img: 'imgurl',
      date: '14:23 12.04.2021',
      title: 'דוחות מיון חודשיים',
      iframe: 'http://iframeurl.com',
    },
    {
      id: 17,
      img: 'imgurl',
      date: '14:23 12.04.2021',
      title: 'דוחות מיון חודשיים',
      iframe: 'http://iframeurl.com',
    },
    {
      id: 10,
      img: 'imgurl',
      date: '14:23 12.04.2021',
      title: 'דוחות מיון שבועיים',
      iframe: 'http://iframeurl.com',
    },
    {
      id: 11,
      img: 'imgurl',
      date: '14:23 12.04.2021',
      title: 'דוחות מיון חודשיים',
      iframe: 'http://iframeurl.com',
    },
    {
      id: 12,
      img: 'imgurl',
      date: '14:23 12.04.2021',
      title: 'דוחות מיון חודשיים',
      iframe: 'http://iframeurl.com',
    },
    {
      id: 13,
      img: 'imgurl',
      date: '14:23 12.04.2021',
      title: 'דוחות מיון חודשיים',
      iframe: 'http://iframeurl.com',
    },
    {
      id: 14,
      img: 'imgurl',
      date: '14:23 12.04.2021',
      title: 'דוחות מיון חודשיים',
      iframe: 'http://iframeurl.com',
    },
    {
      id: 15,
      img: 'imgurl',
      date: '14:23 12.04.2021',
      title: 'דוחות מיון חודשיים',
      iframe: 'http://iframeurl.com',
    },
    {
      id: 16,
      img: 'imgurl',
      date: '14:23 12.04.2021',
      title: 'דוחות מיון חודשיים',
      iframe: 'http://iframeurl.com',
    },
    {
      id: 17,
      img: 'imgurl',
      date: '14:23 12.04.2021',
      title: 'דוחות מיון שנתיים',
      iframe: 'http://iframeurl.com',
    },
  ]);
  const [users, setUsers] = useState([
    {
      id: 1,
      userName: 'david guetta',
      full_name: 'דוד גוואטה',
      full_name_english: 'david guetta amsalem',
    },
    {
      id: 2,
      userName: 'david guetta',
      full_name: 'דניאל בלה',
      full_name_english: 'david guetta amsalem',
    },
    {
      id: 3,
      userName: 'david guetta',
      full_name: 'שיר גוואטה',
      full_name_english: 'david guetta amsalem',
    },
    {
      id: 4,
      userName: 'david guetta',
      full_name: 'דוד גוואטה',
      full_name_english: 'david guetta amsalem',
    },
    {
      id: 5,
      userName: 'david guetta',
      full_name: 'דוד גוואטה',
      full_name_english: 'david guetta amsalem',
    },
    {
      id: 6,
      userName: 'david guetta',
      full_name: 'דוד גוואטה',
      full_name_english: 'david guetta amsalem',
    },
    {
      id: 7,
      userName: 'david guetta',
      full_name: 'דוד גוואטה',
      full_name_english: 'david guetta amsalem',
    },
    {
      id: 8,
      userName: 'david guetta',
      full_name: 'דוד גוואטה',
      full_name_english: 'david guetta amsalem',
    },
    {
      id: 9,
      userName: 'david guetta',
      full_name: 'דוד גוואטה',
      full_name_english: 'david guetta amsalem',
    },
    {
      id: 10,
      userName: 'david guetta',
      full_name: 'דוד גוואטה',
      full_name_english: 'david guetta amsalem',
    },
    {
      id: 11,
      userName: 'david guetta',
      full_name: 'דוד גוואטה',
      full_name_english: 'david guetta amsalem',
    },
    {
      id: 12,
      userName: 'david guetta',
      full_name: 'דוד גוואטה',
      full_name_english: 'david guetta amsalem',
    },
  ]);

  const logout = () => {
    services.auth.logout();

    setUser(null);

    navigate('/login', { replace: true });
  };

  return (
    <userContext.Provider
      value={{
        user,
        setUser,
        tree,
        setTree,
        loading,
        setLoading,
        reports,
        setReports,
        users,
        setUsers,
        logout,
      }}
    >
      {children}
    </userContext.Provider>
  );
};

export default UserProvider;

import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import UserTreeItem from '../../components/users/TreeItem';
import UsersScroll from '../../components/UsersScroll';
import { services } from '../../services';

const SiteUsers = () => {
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [height, setHeight] = useState('100%');

  const [userSearch, setUserSearch] = useState('');
  const [userRole, setUserRole] = useState('');
  const [newPass, setNewPass] = useState('');
  const [newRole, setNewRole] = useState('');
  const [displayUser, setDisplayUser] = useState(null);
  const [displayPerms, setDisplayPerms] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const handleUserClick = (user) => {
    setDisplayUser(user);
    setDisplayPerms(user.permissions);
    setNewRole(user.role);
  };

  const fetchSiteGroups = async () => {
    try {
      const groups = await services.group.groups();
      setGroups(groups);
    } catch (e) {}
  };

  const fetchUsers = async () => {
    try {
      const users = await services.user.allUsers();

      setUsers(users);

      setFilteredUsers([]);
    } catch (e) {}
  };

  const getFilteredUsers = () => {
    if (!userSearch.length && !userRole.length) {
      return users;
    }

    return filteredUsers;
  };

  useEffect(() => {
    fetchSiteGroups();
    fetchUsers();

    const header = document.querySelector('.header');
    const users = document.querySelector('.users-page');

    const offset = users.offsetTop - header.clientHeight + 50;

    const height = `calc(100% - ${offset}px)`;
    setHeight(height);
  }, []);

  const cancelUserEdit = () => {
    setDisplayUser(null);
    setDisplayPerms([]);
    setNewPass('');
  };

  const saveUserEdit = async () => {
    try {
      const updateData = {
        permissions: displayPerms,
        role: newRole,
      };

      if (newPass) {
        updateData.password = newPass;
      }

      await services.user.updateUser(displayUser.id, updateData);

      toast.success('פרטי המשתמש עודכנו');

      cancelUserEdit();

      await fetchUsers();
      return true;
    } catch (e) {
      toast.error('קרתה שגיאה');
      return false;
    }
  };

  const handleSearch = (val, role) => {
    setUserSearch(val);
    setUserRole(role);
    setDisplayUser(null);
    setDisplayPerms([]);
    const userFilter = users.filter((user) => {
      return (
        (user.username.indexOf(val) !== -1 ||
          (user.full_name_eng && user.full_name_eng.indexOf(val) !== -1) ||
          (user.full_name_heb && user.full_name_heb.indexOf(val) !== -1)) &&
        (!role.length || (role != null && user.role === role))
      );
    });

    console.log(userFilter);

    setFilteredUsers(userFilter);
  };

  return (
    <>
      <div className="">
        <div className="site-title-actions">
          <h1 className="site-title">משתמשים</h1>

          <div className="site-title-actions-inner">
            <input
              type="search"
              placeholder="חיפוש משתמשים"
              value={userSearch}
              onChange={(e) => handleSearch(e.target.value, userRole)}
            />

            <select
              value={userRole}
              onChange={(e) => handleSearch(userSearch, e.target.value)}
              name="role"
              id="role"
            >
              <option value="">תפקיד</option>
              <option value="admin">Admin</option>
              <option value="user">User</option>
            </select>
          </div>
        </div>
        <div className="users-page" style={{ height }}>
          <div className="users-page-content">
            <div className="users-list">
              <UsersScroll
                users={getFilteredUsers()}
                fetchUsers={fetchUsers}
                onUserClick={handleUserClick}
              />
            </div>
            {displayUser && (
              <div className="users-edit-content">
                <div className="user-edit-actions">
                  <h4>עריכת משתמש ({displayUser.username}):</h4>
                  <div className="actions-btns">
                    <button onClick={() => saveUserEdit()} className="site-btn">
                      שמור
                    </button>
                    <button
                      onClick={() => cancelUserEdit()}
                      className="site-btn"
                    >
                      ביטול
                    </button>
                  </div>
                </div>
                <div className="user-edit-password">
                  <input
                    type="text"
                    onChange={(e) => setNewPass(e.target.value)}
                    placeholder="סיסמא חדשה (השאירו ריק בכדי לא לשנות)"
                  />
                </div>
                <div className="user-edit-role">
                  <select onChange={(e) => setNewRole(e.target.value)}>
                    <option value="admin" selected={newRole == 'admin'}>
                      אדמין
                    </option>
                    <option value="user" selected={newRole == 'user'}>
                      משתמש
                    </option>
                  </select>
                </div>
                <ul>
                  {groups.map((group) => (
                    <UserTreeItem
                      key={group.id}
                      item={group}
                      permissions={displayPerms}
                      setPermissions={setDisplayPerms}
                    />
                  ))}
                  {/* {tree.map((item) => (
                    <UserTreeItem
                      key={item.id}
                      item={item}
                      permissions={displayPerms}
                      setPermissions={setDisplayPerms}
                    />
                  ))} */}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SiteUsers;

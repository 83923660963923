import React from 'react';

const UserTreeItem = ({ item, permissions, setPermissions }) => {
	const onPermChecked = (e) => {
		const newPerms = [...permissions];

		if (e.target.checked) {
			setPermissions([...newPerms, ...[item.identifier || item.id]]);
		} else {
			newPerms.splice(newPerms.indexOf(item.identifier || item.id), 1);

			setPermissions([...newPerms]);
		}
	};

	return (
		<>
			<li>
				<label>
					<input
						onChange={onPermChecked}
						checked={permissions.includes(
							item.identifier || item.id,
						)}
						type="checkbox"
					/>
					<span>{item.title || item.name}</span>
				</label>

				{item.children && item.children.length > 0 && (
					<ul>
						{item.children.map((childItem) => (
							<UserTreeItem
								key={childItem.id}
								item={childItem}
								permissions={permissions}
								setPermissions={setPermissions}
							/>
						))}
					</ul>
				)}
			</li>
		</>
	);
};

export default UserTreeItem;

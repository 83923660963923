import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import './services/Api';

import './assets/styles/index.scss';

import Dashboard from './layouts/Dashboard';

import SiteTree from './pages/dashboard/SiteTree';
import SiteReports from './pages/dashboard/SiteReports';
import SiteUsers from './pages/dashboard/SiteUsers';

import Login from './pages/Login';
import { AppProviders } from './providers';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <BrowserRouter>
    <AppProviders>
      <Routes>
        <Route path="/" element={<Dashboard />}>
          <Route index element={<SiteTree />} />
          <Route path="site-reports" element={<SiteReports />} />
          <Route path="site-users" element={<SiteUsers />} />
        </Route>
        <Route path="/login" element={<Login />}></Route>
      </Routes>
    <ToastContainer/>
    </AppProviders>
  </BrowserRouter>,
  document.getElementById('root')
);

import React from 'react';
import '@nosferatu500/react-sortable-tree/style.css';
import SortableTree, {
  removeNodeAtPath,
} from '@nosferatu500/react-sortable-tree';
import NodeTreeRenderer from '../components/NodeTreeRenderer';

const RouteTree = ({
  tree,
  updateTree,
  deleteItem,
  setShowDelete,
  setShowEditBox,
  setNodeToDelete,
  setNodeToEdit,
  setShowAddBox,
  setParentNode,
  searchQuery,
}) => {
  const onTreeChange = (treeData) => {
    updateTree(treeData);
  };

  const generateNodeProps = ({
    node,
    path,
    treeIndex,
    lowerSiblingCounts,
    isSearchMatch,
    isSearchFocus,
  }) => {
    const reports = node.reports || [];

    return {
      buttons: [
        node.level < 3 && !reports.length && (
          <button
            className="tree-edit"
            onClick={() => {
              setParentNode({ node, path });
              setShowAddBox(true);
            }}
          >
            הוספת רמה
          </button>
        ),
        <button
          className="tree-edit"
          onClick={() => {
            editTreeItem(node, path, treeIndex);
          }}
        >
          ערוך
        </button>,
        <button
          className="tree-edit"
          disabled={node.children && node.children.length > 0}
          onClick={() => deleteTreeItem(node, path, treeIndex)}
        >
          מחק
        </button>,
      ],
    };
  };

  function generateNodeKey({ node, treeIndex }) {
    return treeIndex;
  }

  function deleteTreeItem(node, path, treeIndex) {
    setNodeToDelete({ node, path });
    setShowDelete(true);
    // deleteItem(node.id, path);
  }

  function editTreeItem(node, path, treeIndex) {
    setNodeToEdit({ node: { children: [], ...node }, path, treeIndex });
    setShowEditBox(true);
  }

  return (
    <>
      <SortableTree
        treeData={tree}
        rowDirection={'rtl'}
        maxDepth={3}
        rowHeight={65}
        searchQuery={searchQuery}
        searchFocusOffset={0}
        getNodeKey={generateNodeKey}
        generateNodeProps={generateNodeProps}
        nodeContentRenderer={NodeTreeRenderer}
        onChange={(treeData) => onTreeChange(treeData)}
      />
    </>
  );
};

export default RouteTree;

import React from 'react';
import close from '../../assets/images/close.svg';

const FilterGroup = ({
	filterGroups,
	filterdGroups,
	updateSelectedGroups,
	selectedGroups,
	deleteItem,
	title,
	placeholder,
	onClick
}) => {
	return (
		<div onClick={onClick}>
			<div className="form-group">
				<label>{title}</label>
				<input
					type="text"
					placeholder={placeholder}
					onChange={filterGroups}
				/>
			</div>
			<div className="form-group">
				<ul style={{ maxHeight: '60vh', overflow: 'auto' }}>
					{filterdGroups.map((group) => (
						<li
							key={group.id}
							style={{
								margin: '10px 0',
								display: 'flex',
								justifyContent: 'space-between',
								padding: '0 5px',
							}}
						>
							<label
								style={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<input
									type="checkbox"
									checked={selectedGroups.includes(
										group.identifier || group.id,
									)}
									onChange={(e) => {
										updateSelectedGroups(
											group.identifier || group.id,
											e.target.checked,
										);
									}}
									style={{ marginLeft: '10px' }}
								/>
								<span>{group.name}</span>
							</label>
							{!!deleteItem && (
								<div
									style={{ width: '15px', cursor: 'pointer' }}
									onClick={() => deleteItem(group.id)}
								>
									<img
										style={{
											height: '100%',
											width: '100%',
										}}
										src={close}
										alt={close}
									/>
								</div>
							)}
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default FilterGroup;

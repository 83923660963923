import Api from "./Api";

class TagService {
    async tags() {
        try {
            const {
                data: {
                    data: { tags },
                },
            } = await Api.get("tags");

            return tags;
        } catch (e) {
            throw e;
        }
    }

    async addTag(name) {
        try {
            const { data } = await Api.post(`tags?name=${name}`);

            return data.data.tag;
        } catch (e) {
            throw e;
        }
    }

    async deleteTag(id, reportId) {
        try {
            const {
                data: { data },
            } = await Api.delete(`tags/${id}?reportId=${reportId}`);
            return data.success;
        } catch (e) {
            throw e;
        }
    }
}

export default new TagService();

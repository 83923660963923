import AuthService from './AuthService';
import TreeService from './TreeService';
import ReportService from './ReportService';
import UserService from './UserService';
import GroupsService from './GroupsService';
import TagService from './TagService';

export const services = {
	auth: AuthService,
	tree: TreeService,
	report: ReportService,
	user: UserService,
	group: GroupsService,
	tag: TagService,
};

import Modal from '../modal/Modal';

const ConfirmDeleteTree = ({ node, setShowDeleteBox, deleteTree }) => {
  const onClickOutside = () => {
    setShowDeleteBox(false);
  };

  const handleDeleteItem = () => {
    deleteTree(node);
  };

  return (
    <Modal
      title={`אישור מחיקת רמה - ${node.node.title}`}
      onClose={onClickOutside}
    >
      <div style={{ textAlign: 'center' }}>
        <button onClick={handleDeleteItem} className="site-btn">
          אישור מחיקה
        </button>
        <button onClick={onClickOutside} className="site-btn">
          ביטול
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteTree;

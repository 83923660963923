import Modal from '../modal/Modal';

const ConfirmDeleteReport = ({ report, setShowDeleteBox, deleteReport }) => {
  const onClickOutside = () => {
    setShowDeleteBox(false);
  };

  const handleDeleteItem = () => {
    deleteReport(report);
  };

  return (
    <Modal
      title={`אישור מחיקת דוח - ${report.name}`}
      onClickOutside={onClickOutside}
    >
      <div style={{ textAlign: 'center' }}>
        <button onClick={handleDeleteItem} className="site-btn">
          אישור מחיקה
        </button>
        <button onClick={onClickOutside} className="site-btn">
          ביטול
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteReport;

import React, { useEffect, useState, useMemo } from 'react';
import EditReportRow from '../../components/EditReportRow';
import { services } from '../../services';
import ConfirmDeleteReport from '../../components/reports/ConfirmDelete';
import EditReport from '../../components/reports/EditReport';
import { isReportInTree } from '../../utils';
import Select from 'react-select';
import { toast } from 'react-toastify';

const selectValues = [
  { value: { order: 1, field: 'name' }, label: 'מיון לפי א - ת' },
  { value: { order: -1, field: 'name' }, label: 'מיון לפי ת - א' },
  { value: { order: 1, field: 'date' }, label: 'מיון מהישן לחדש' },
  { value: { order: -1, field: 'date' }, label: 'מיון מהחדש לישן' },
];

const SiteReports = () => {
  const [reports, setReports] = useState([]);
  const [showEditBox, setShowEditBox] = useState(false);
  const [tree, setTree] = useState([]);
  const [reportToEdit, setReportToEdit] = useState(null);
  const [reportToDelete, setReportToDelete] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [showAddReport, setShowAddReport] = useState(false);

  const [height, setHeight] = useState('100%');
  const [filter, setFilter] = useState({
    search: '',
    related: true,
    notRelated: true,
  });
  const [sort, setSort] = useState(null);

  const filteredReports = useMemo(() => {
    let filtered = reports;
    if (filter.search.length > 0) {
      const val = filter.search;
      filtered = filtered.filter(
        (report) => report.name.includes(val) || report.url.includes(val)
      );
    }
    if (!filter.related) {
      filtered = filtered.filter((report) => {
        let inTree = false;
        for (let child of tree) {
          inTree = inTree || isReportInTree(child, report.id);
        }
        return !inTree;
      });
    }

    if (!filter.notRelated) {
      filtered = filtered.filter((report) => {
        let inTree = false;
        for (let child of tree) {
          inTree = inTree || isReportInTree(child, report.id);
        }
        return inTree;
      });
    }

    if (sort) {
      const order = sort.value.order;
      const field = sort.value.field;
      filtered = filtered.sort((report1, report2) => {
        return order === 1
          ? report1[field].localeCompare(report2[field])
          : report2[field].localeCompare(report1[field]);
      });
    }

    return filtered;
  }, [reports, filter, sort]);

  const deleteReport = async (report) => {
    try {
      await services.report.deleteReport(report.id);

      setOpenDelete(false);

      toast.success('הדוח נמחק');

      await fetchSiteReports();
      return true;
    } catch (e) {
      toast.error('קרתה שגיאה');
      return false;
    }
  };

  const editReport = async (
    id,
    name,
    url,
    description,
    date,
    img,
    tags,
    shouldDeleteImg
  ) => {
    try {
      const updated = await services.report.editReport(
        id,
        name,
        url,
        description,
        date,
        img,
        tags,
        shouldDeleteImg
      );

      if (updated) {
        setShowEditBox(false);
        toast.success('הדוח התעדכן');
        await fetchSiteReports();
      }

      return true;
    } catch (e) {
      toast.error('קרתה שגיאה');
      const error = e.response.data.error;
      if (error === 'url_exists') {
        throw new Error('כתובת ה url תפוס !');
      }
      return false;
    }
  };

  const addReport = async (
    id,
    name,
    url,
    description,
    date,
    img,
    selectedTags
  ) => {
    try {
      await services.report.addReport(
        name,
        url,
        description,
        date,
        img,
        typeof selectedTags === 'string' && selectedTags.length
          ? selectedTags.split(',')
          : Array.isArray(selectedTags)
          ? selectedTags
          : []
      );

      setShowAddReport(false);

      toast.success('הדוח נוצר');

      await fetchSiteReports();

      return true;
    } catch (e) {
      toast.error('קרתה שגיאה');
      const error = e.response.data.error;
      if (error === 'Validation error') {
        throw new Error('כתובת ה url תפוס !');
      }
      return false;
    }
  };

  const fetchSiteReports = async () => {
    try {
      const reports = await services.report.allReports();

      setReports(reports);
    } catch (e) {}
  };

  const fetchTree = async () => {
    const tree = await services.tree.tree();
    setTree(tree);
  };

  useEffect(() => {
    fetchSiteReports();
    fetchTree();
  }, []);

  useEffect(() => {
    const header = document.querySelector('.header');
    const tree = document.querySelector('.editReportsScroll');

    const offset = tree.offsetTop - header.clientHeight + 50;

    const height = `calc(100% - ${offset}px)`;
    setHeight(height);

    handleSearchReport(filter.search);
  }, [reports]);

  const handleSearchReport = (val) => {
    setFilter({ ...filter, search: val });
  };

  const handleEditBox = (report, index) => {
    setReportToEdit({ report, index });
    setShowEditBox(true);
  };

  const handleDeleteBox = (report) => {
    setReportToDelete(report);
    setOpenDelete(true);
  };

  const renderRow = (report, index) => {
    return (
      <EditReportRow
        key={report.id}
        report={report}
        clickOnBox={handleEditBox}
        clickToDelete={handleDeleteBox}
        index={report.id}
        tree={tree}
      />
    );
  };

  return (
    <>
      {showEditBox && (
        <EditReport
          nodeToEdit={reportToEdit}
          title={`עריכת דוח - ${reportToEdit.report.name}`}
          finishCallback={editReport}
          toggleModalCallback={setShowEditBox}
        />
      )}

      {showAddReport && (
        <EditReport
          nodeToEdit={{ report: null }}
          title={`הוספת דוח חדש`}
          finishCallback={addReport}
          toggleModalCallback={setShowAddReport}
        />
      )}

      {openDelete && (
        <ConfirmDeleteReport
          report={reportToDelete}
          deleteReport={deleteReport}
          setShowDeleteBox={setOpenDelete}
        />
      )}

      <div className="">
        <div className="site-title-actions">
          <h1 className="site-title">דוחות</h1>
          <Select
            className="select"
            placeholder="מיון..."
            defaultValue={sort}
            onChange={setSort}
            options={selectValues}
          />
          <div className="site-title-actions-inner">
            <button className="site-btn" onClick={() => setShowAddReport(true)}>
              הוספת דוח
            </button>

            <input
              type="search"
              placeholder='חיפוש דו"ח'
              value={filter.search}
              onChange={(e) => handleSearchReport(e.target.value)}
            />
            <div className="checkbox__container">
              <input
                type="checkbox"
                name=""
                id="related"
                checked={filter.related}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    related: !filter.related,
                  })
                }
              />
              <label htmlFor="related">הצג דוחות משוייכים לעץ</label>
              <input
                type="checkbox"
                name=""
                id="not-related"
                checked={filter.notRelated}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    notRelated: !filter.notRelated,
                  })
                }
              />
              <label htmlFor="not-related">הצג דוחות לא משוייכים לעץ</label>
            </div>
          </div>
        </div>

        <div
          className="editReportsScroll"
          style={{
            height: height,
            overflowY: 'scroll',
            overflowX: 'visible',
          }}
        >
          <div className="reports-table__header">
            <div>מספר דוח</div>
            <div>שם דוח</div>
            <div>כתובת דוח</div>
            <div>משוייך בעץ</div>
            <div>נוצר ע״י</div>
            <div>נערך ע״י</div>
            <div>תאריך</div>
          </div>
          {filteredReports.map((report, index) => renderRow(report, index))}
        </div>
      </div>
    </>
  );
};

export default SiteReports;

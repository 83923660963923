export const isReportInTree = (node, reportId) => {
	if (node?.children?.length > 0) {
		let inTree = false;
		for (let child of node.children) {
			inTree = inTree || isReportInTree(child, reportId);
		}
		return inTree;
	}
	return node?.reports?.includes(reportId) || false;
};

export const findNode = (tree, id) => {
	if (tree.id === id) return tree;
	if (tree.children && tree.children.length > 0) {
		let node;
		for (const child of tree.children) {
			if (!!node) return node;
			node = findNode(child, id);
		}
		return node;
	}
};

import React, { useState } from 'react';
import { services } from '../services';
import EditUserBox from './EditUserBox';
import UserRow from './UserRow';
import ConfirmDeleteUser from './users/ConfirmDelete';

const UsersScroll = ({ users, onUserClick, fetchUsers }) => {
  const [showDeleteBox, setShowDeleteBox] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const openDeleteBox = (user) => {
    setUserToDelete(user);
    setShowDeleteBox(true);
  };

  const deleteUser = async (user) => {
    try {
      await services.user.deleteUser(user.id);

      setUserToDelete(false);
      setShowDeleteBox(false);

      await fetchUsers();
      return true;
    } catch (e) {
      return false;
    }
  };

  return (
    <>
      {showDeleteBox && (
        <ConfirmDeleteUser
          user={userToDelete}
          deleteUser={deleteUser}
          setShowDeleteBox={setShowDeleteBox}
        />
      )}
      <div className="users-scroll">
        {/* {showEditBox && <EditUserBox onClose={closeBox} />} */}

        <div className="users-wrapper">
          <div className='users-header'>
            <p>תפקיד</p>
            <p>שם בעברית</p>
            <p>שם באנגלית</p>
            <p>שם משתמש</p>
            <p>עריכה</p>
          </div>
          {users.map((user) => (
            <UserRow
              key={user.id}
              user={user}
              onUserClick={onUserClick}
              onDeleteClick={openDeleteBox}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default UsersScroll;

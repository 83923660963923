import { useEffect, useMemo, useState } from 'react';
import { publicUrl } from '../../providers';
import Modal from '../modal/Modal';
import EditReport from '../../components/reports/EditReport';
import { services } from '../../services';
import FilterGroup from './FilterGroup';

const sortReportsArrayMethod = (reportA, reportB) => {
  if (reportA.name > reportB.name) return 1;
  if (reportA.name < reportB.name) return -1;
  return 0;
};

const EditTreeItem = ({
  reports,
  nodeToEdit,
  editTreeItem,
  setShowEditBox,
  setAddReportModal,
  setReports,
  newReportAdded,
  setNewReportAdded,
}) => {
  const [icon, setIcon] = useState(null);
  const [groups, setGroups] = useState([]);
  const [title, setTitle] = useState(nodeToEdit.node.title);
  const [slug, setSlug] = useState(nodeToEdit.node.slug);
  const [isLast, setIsLast] = useState(nodeToEdit.node.is_last);
  const [filterdGroups, setFilterdGroups] = useState(groups);
  const [selectedGroups, setSelectedGroups] = useState(nodeToEdit.node.groups);
  const [filterdReports, setFilterdReports] = useState([]);
  const [selectedReports, setSelectedReports] = useState(
    nodeToEdit.node.reports || []
  );

  const sortedSelectedReportNames = useMemo(() => {
    const unsortedSelectedReports = [];

    reports.forEach((report) => {
      if (Array.from(selectedReports).includes(report.id))
        return unsortedSelectedReports.push(report);
    });

    const sortedReports = unsortedSelectedReports.sort(sortReportsArrayMethod);

    return sortedReports.map((report) => report.name);
  }, [selectedReports]);

  const [error, setError] = useState(false);
  const [errMessage, setErrMessage] = useState('');

  const sortReports = () => {
    const uncheckedReports = [];

    const checkedReports = reports.filter((report) => {
      if (Array.from(selectedReports).includes(report.id)) return true;
      uncheckedReports.push(report);
    });

    const sortedCheckedReports = checkedReports.sort(sortReportsArrayMethod);

    const sortedUncheckedReports = uncheckedReports.sort(
      sortReportsArrayMethod
    );

    const sortedReports = [...sortedCheckedReports, ...sortedUncheckedReports];

    setFilterdReports(sortedReports);
  };

  useEffect(() => {
    sortReports();
  }, [selectedReports]);

  useEffect(() => {
    if (newReportAdded) {
      setSelectedReports([...nodeToEdit.node.reports, newReportAdded]);
      setNewReportAdded(null);
    }
  }, [newReportAdded]);

  const onClickOutside = () => {
    setShowEditBox(false);
  };

  const itemValid = () => {
    if (!(title.length > 0 && slug.length > 0)) {
      setError(true);
      setErrMessage('חובה למלא כותרת ומזהה ייחודי');
      return false;
    }
    const regex = new RegExp(/[\u0590-\u05FF]/);
    if (regex.test(slug)) {
      setError(true);
      setErrMessage('אין להזין תווים בעברית');
      return false;
    }
    return true;
  };

  const updateChildrenGroups = (item, groups) => {
    item.children = item.children.map((childItem) => {
      childItem.groups = groups;

      if (childItem.children && childItem.children.length > 0) {
        childItem.children = updateChildrenGroups(childItem, groups);
      }

      return childItem;
    });

    return item.children;
  };

  const handleEditTreeItem = async (e) => {
    e.preventDefault();

    if (!itemValid()) {
      return;
    }

    const {
      tree,
      reports: newReports,
      selectedReports: reportsSelected,
    } = await editTreeItem(
      nodeToEdit.node.id,
      title,
      slug,
      isLast,
      selectedReports,
      icon,
      selectedGroups,
      nodeToEdit.node.children
    );

    const item = {
      ...tree,
    };

    if (item.children && item.children.length > 0) {
      item.children = updateChildrenGroups(item, selectedGroups);
    }

    setError(tree);
    if (tree) setErrMessage('המזהה היחודי תפוס');
    else setErrMessage('');
  };

  const toggleSelectedReport = (id, checked) => {
    let selected = [...selectedReports];

    if (checked) {
      selected = [...selected, ...[id]];
    } else {
      selected.splice(selected.indexOf(id), 1);
    }

    selected = selected.sort(sortReportsArrayMethod);

    setSelectedReports(selected);
  };

  const updateSelectedGroups = (groupId, checked) => {
    const activeGroups = [...selectedGroups];
    if (checked) {
      activeGroups.push(groupId);
    } else activeGroups.splice(activeGroups.indexOf(groupId), 1);
    setSelectedGroups(activeGroups);
  };

  const filterReports = (e) => {
    const newReports = reports.filter((report) =>
      report.name.includes(e.target.value)
    );
    setFilterdReports(newReports);
  };

  const filterGroups = (e) => {
    const newGroups = groups.filter((group) =>
      group.name.includes(e.target.value)
    );
    setFilterdGroups(newGroups);
  };

  const updateSelectedReports = (report) => {
    const source = report.source?.index;
    const destination = report.destination?.index;
    if (source === null || destination === null) return;
    const newSelectedReports = [...selectedReports];
    newSelectedReports.splice(source, 1);
    newSelectedReports.splice(destination, 0, parseInt(report.draggableId));
    setSelectedReports(newSelectedReports);
  };

  const fetchGroups = async () => {
    try {
      const groups = await services.group.groups();
      setGroups(groups);
      setFilterdGroups(groups);
    } catch (e) {}
  };

  useEffect(() => {
    fetchGroups();

    return () => onClickOutside();
  }, []);

  useEffect(() => {
    setSelectedGroups(nodeToEdit.node.groups);
  }, [nodeToEdit]);

  return (
    <Modal
      title={`עריכת רמה (${nodeToEdit.node.title})`}
      large
      onClose={onClickOutside}
    >
      <form
        onSubmit={handleEditTreeItem}
        className="add-tree-form"
        style={{ display: 'grid' }}
      >
        <div>
          {nodeToEdit.node.level === 1 && (
            <div className="form-group">
              <label htmlFor="icon">אייקון תפריט:</label>
              <img
                className="tree-icon"
                src={publicUrl(nodeToEdit.node.icon)}
                alt=""
              />

              <input
                id="img"
                onChange={(e) => setIcon(e.target.files[0])}
                type="file"
                accept="image/*"
              />
            </div>
          )}
          <div className="form-group">
            <label htmlFor="title">כותרת רמה:</label>
            <input
              id="title"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              type="text"
              placeholder="כותרת רמה"
            />
          </div>
          <div className="form-group">
            <label htmlFor="slug">מזהה יחודי:</label>
            <input
              id="slug"
              onChange={(e) => setSlug(e.target.value)}
              value={slug}
              type="text"
              placeholder="מזהה יחודי לרמה"
            />
            {error && <div className="form-error">{errMessage}</div>}
          </div>
          {!nodeToEdit.node?.children ||
            (nodeToEdit.node.children.length === 0 && (
              <FilterGroup
                {...{
                  filterdGroups,
                  filterGroups,
                  updateSelectedGroups,
                  selectedGroups,
                }}
                title="שיוך קבוצות:"
                placeholder="חיפוש קבוצה..."
              />
            ))}
          <div
            style={{
              marginBottom: 20,
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              gap: 2,
            }}
          >
            {sortedSelectedReportNames &&
              sortedSelectedReportNames.length > 0 && (
                <>
                  <div className="form-group">
                    <label htmlFor="selectedReports">דוחות משוייכים:</label>
                  </div>
                  {sortedSelectedReportNames.map(
                    (selectedReportName, index) => {
                      return (
                        <span
                          style={{ color: 'black', padding: 5, height: 30 }}
                          key={`${selectedReportName}_${index}`}
                        >
                          {selectedReportName}
                        </span>
                      );
                    }
                  )}
                </>
              )}
          </div>
        </div>
        <div>
          {!nodeToEdit.node.children ||
          nodeToEdit.node.children.length === 0 ? (
            <>
              <div className="form-group">
                <label htmlFor="reports">חיפוש דוחות:</label>
                <input
                  id="reports"
                  type="text"
                  placeholder="חיפוש דוחות..."
                  onChange={filterReports}
                />
              </div>
              <div className="group-container">
                <div className="form-group" style={{ width: '100%' }}>
                  <div className="ReactVirtualized__Grid__innerScrollContainer custom">
                    {filterdReports.map((filterdReport, key) => (
                      <label key={key} className="tree-report">
                        <input
                          type="checkbox"
                          value={true}
                          onChange={(e) =>
                            toggleSelectedReport(
                              filterdReport.id,
                              e.target.checked
                            )
                          }
                          checked={Array.from(selectedReports).includes(
                            filterdReport.id
                          )}
                        />
                        <span>{filterdReport.name}</span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="site-btn"
                onClick={(e) => {
                  // e.stopPropagation();
                  setAddReportModal(true);
                }}
              >
                הוספת דוח
              </button>
            </>
          ) : (
            <FilterGroup
              {...{
                filterdGroups,
                filterGroups,
                updateSelectedGroups,
                selectedGroups,
                title: 'שיוך קבוצות:',
                placeholder: 'חיפוש קבוצה...',
              }}
            />
          )}
        </div>
        <div className="submit-container">
          <button className="site-btn" type="submit">
            שמור
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default EditTreeItem;

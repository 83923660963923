import {
  addNodeUnderParent,
  changeNodeAtPath,
  getNodeAtPath,
  insertNode,
  removeNodeAtPath,
  toggleExpandedForAll,
} from '@nosferatu500/react-sortable-tree';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Modal from '../../components/modal/Modal';
import EditReport from '../../components/reports/EditReport';
import RouteTree from '../../components/RouteTree';
import AddTreeItem from '../../components/tree/AddTreeItem';
import ConfirmDeleteTree from '../../components/tree/ConfirmDelete';
import EditTreeItem from '../../components/tree/EditTreeItem';
import { services } from '../../services';
import { findNode } from '../../utils';
import ClipLoader from 'react-spinners/ClipLoader';

const SiteTree = () => {
  const [tree, setTree] = useState(false);
  const [reports, setReports] = useState([]);
  const [saving, setSaving] = useState(false);
  const [showAddBox, setShowAddBox] = useState(false);
  const [showEditBox, setShowEditBox] = useState(false);
  const [showDeleteBox, setShowDelete] = useState(false);
  const [nodeToEdit, setNodeToEdit] = useState(null);
  const [nodeToDelete, setNodeToDelete] = useState(null);
  const [parentNode, setParentNode] = useState(null);
  const [addReportModal, setAddReportModal] = useState(false);
  const [showSuccessfulModal, setShowSuccessfulModal] = useState(false);
  const [treeSearchQuery, setTreeSearchQuery] = useState('');
  const [newReportAdded, setNewReportAdded] = useState(null);

  const [height, setHeight] = useState('100%');

  function generateNodeKey({ node, treeIndex }) {
    return treeIndex;
  }

  useEffect(() => {}, [showEditBox]);

  const addTreeItem = async (title, slug) => {
    try {
      const newNode = await services.tree.addTreeItem(
        title,
        slug,
        !!parentNode ? 0 : tree.length,
        !!parentNode && parentNode.node.id,
        !!parentNode && parentNode.node.level + 1
      );

      // const insertData = insertNode({
      // 	treeData: tree,
      // 	depth: 0,
      // 	path: 0,
      // 	newNode: newNode,
      // 	minimumTreeIndex: tree.length,
      // 	ignoreCollapsed: false,
      // 	addAsFirstChild: true,
      // 	getNodeKey: generateNodeKey,
      // });

      let insertData;

      if (!!parentNode) {
        let parent = getNodeAtPath({
          treeData: tree,
          path: parentNode.path,
          getNodeKey: generateNodeKey,
          ignoreCollapsed: true,
        });

        let parentKey = generateNodeKey(parent);

        insertData = addNodeUnderParent({
          treeData: tree,
          newNode: newNode,
          parentKey,
          expandParent: true,
          addAsFirstChild: true,
          ignoreCollapsed: true,
          getNodeKey: generateNodeKey,
        });
      } else {
        insertData = insertNode({
          path: 0,
          depth: 0,
          treeData: tree,
          newNode: newNode,
          addAsFirstChild: true,
          minimumTreeIndex: tree.length,
          ignoreCollapsed: true,
          getNodeKey: generateNodeKey,
        });
      }

      setShowAddBox(false);
      setShowSuccessfulModal(true);
      setTree(insertData.treeData);
      setTreeSearchQuery(title);

      setTimeout(() => {
        setTreeSearchQuery('');
      }, 2000);

      toast.success('הרמה נוספה');

      return true;
    } catch (e) {
      toast.error('קרתה שגיאה');
      return 'מזהה יחודי כבר קיים';
    }
  };

  const renderTreeWithExpanded = (originalTree, items) => {
    return items.map((treeItem, index) => {
      treeItem.expanded = originalTree[index].expanded;

      if (treeItem.children && treeItem.children.length) {
        treeItem.children = renderTreeWithExpanded(
          originalTree[index].children,
          treeItem.children
        );
      }

      return treeItem;
    });
  };

  const editTreeItem = async (
    id,
    title,
    slug,
    isLast,
    selectedReports,
    icon,
    selectedGroups,
    children
  ) => {
    try {
      setSaving(true);

      const newNode = await services.tree.editTreeItem(
        id,
        title,
        slug,
        isLast,
        selectedReports,
        icon,
        selectedGroups,
        children
      );

      const newTree = changeNodeAtPath({
        treeData: tree,
        path: nodeToEdit.path,
        newNode: { ...nodeToEdit.node, ...newNode },
        getNodeKey: generateNodeKey,
      });

      setShowEditBox(false);

      setTree(newTree);

      const treeItems = await fetchSiteTree(false);

      toast.success('הרמה עודכנה');

      setTree(renderTreeWithExpanded(tree, treeItems));

      setSaving(false);

      return {
        tree: newTree.find((item) => findNode(item, id)),
        reports: reports,
        selectedReports: selectedReports,
      };
    } catch (e) {
      setSaving(false);

      const error = e.response.data.error;

      if (error === 'file_not_supported') {
        toast.error('קובץ לא נתמך');
      } else {
        toast.error('קרתה שגיאה');
      }
      return false;
    }
  };

  const fetchSiteTree = async (set = true) => {
    try {
      const tree = await services.tree.tree();
      if (set) {
        setTree(tree);
      }
      await fetchReports();

      return tree;
    } catch (e) {}
  };

  const fetchReports = async () => {
    const reports = await services.report.allReports();
    setReports(reports);
  };

  const updateTreeOrders = (treeData, level = 1) => {
    return Array.from(treeData).map((treeItem, order) => {
      treeItem.order = order;
      treeItem.level = level;

      return {
        ...treeItem,
        ...{
          is_final: level === 3 || treeItem.is_last,
          children: treeItem.children
            ? updateTreeOrders(treeItem.children, level + 1)
            : [],
        },
      };
    });
  };

  const updateTree = async (treeData) => {
    const newData = updateTreeOrders(treeData);

    setTree(newData);

    try {
      await services.tree.updateTree(newData);
    } catch (e) {}
  };

  const deleteItem = async ({ node, path }) => {
    const treeData = removeNodeAtPath({
      treeData: tree,
      path: path,
      getNodeKey: generateNodeKey,
    });

    setSaving(true);

    setShowDelete(false);

    await updateTree(treeData);

    try {
      await services.tree.deleteTreeItem(node.id);
    } catch (e) {}

    setSaving(false);
  };

  const expandTree = (expanded = true) => {
    const items = toggleExpandedForAll({
      treeData: tree,
      expanded: expanded,
    });
    setTree(items);
  };

  const addReport = async (
    id,
    name,
    url,
    description,
    date,
    img,
    selectedTags
  ) => {
    try {
      const report = await services.report.addReport(
        name,
        url,
        description,
        date,
        img,
        typeof selectedTags === 'string' && selectedTags.length
          ? selectedTags.split(',')
          : Array.isArray(selectedTags)
          ? selectedTags
          : []
      );
      await services.tree.addReportToTree(nodeToEdit.node.id, report.id);
      setAddReportModal(false);

      toast.success('הדוח נוצר');

      await fetchSiteTree();

      const treeItems = await fetchSiteTree(false);

      setNewReportAdded(report.id);

      setTree(renderTreeWithExpanded(tree, treeItems));

      return true;
    } catch (e) {
      toast.error('קרתה שגיאה');
      const error = e.response.data.error;
      if (error === 'Validation error') {
        throw new Error('כתובת ה url תפוס !');
      }
      return false;
    }
  };

  useEffect(() => {
    fetchSiteTree();

    const header = document.querySelector('.header');
    const tree = document.querySelector('.tree-wrapper');

    const offset = tree.offsetTop - header.clientHeight + 50;

    const height = `calc(100% - ${offset}px)`;
    setHeight(height);
  }, []);

  return (
    <>
      {showAddBox && (
        <AddTreeItem addTreeItem={addTreeItem} setShowAddBox={setShowAddBox} />
      )}

      {showSuccessfulModal && (
        <Modal onClickOutside={() => setShowSuccessfulModal(false)}>
          <div onClick={() => setShowSuccessfulModal(false)}>
            הרמה נוספה בהצלחה
          </div>
        </Modal>
      )}

      {showEditBox && (
        <EditTreeItem
          reports={reports}
          setReports={setReports}
          editTreeItem={editTreeItem}
          nodeToEdit={nodeToEdit}
          setShowEditBox={setShowEditBox}
          setAddReportModal={setAddReportModal}
          newReportAdded={newReportAdded}
          setNewReportAdded={setNewReportAdded}
        />
      )}

      {showDeleteBox && (
        <ConfirmDeleteTree
          node={nodeToDelete}
          deleteTree={deleteItem}
          setShowDeleteBox={setShowDelete}
        />
      )}

      <div className="container" style={{ maxWidth: '100%', padding: 0 }}>
        <div style={{ padding: '0 20%' }}>
          <h1 className="site-title">עץ אתר</h1>
          <div className="tree-actions">
            <button onClick={() => expandTree(true)}>הרחב הכל</button>
            <button onClick={() => expandTree(false)}>סגור הכל</button>
            <button
              onClick={() => {
                setShowAddBox(true);
                setParentNode(null);
              }}
            >
              הוספת רמה
            </button>
          </div>
        </div>

        <div className="tree-wrapper" style={{ height: height }}>
          {saving ? (
            <div className="saving-tree">
              <ClipLoader
                color={'#000000'}
                loading={saving}
                size={80}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            ''
          )}

          <RouteTree
            searchQuery={treeSearchQuery}
            deleteItem={deleteItem}
            updateTree={updateTree}
            setShowEditBox={setShowEditBox}
            setShowDelete={setShowDelete}
            setNodeToEdit={setNodeToEdit}
            setNodeToDelete={setNodeToDelete}
            nodeToEdit={nodeToEdit}
            showEditBox={showEditBox}
            tree={tree}
            setTree={setTree}
            setShowAddBox={setShowAddBox}
            setParentNode={setParentNode}
          />
        </div>
      </div>
      {addReportModal && (
        <EditReport
          nodeToEdit={{ report: null }}
          title={`הוספת דוח חדש`}
          finishCallback={addReport}
          toggleModalCallback={setAddReportModal}
        />
      )}
    </>
  );
};

export default SiteTree;

import React from 'react';

const UserRow = ({ user, onUserClick, onDeleteClick }) => {
  return (
    <div className="user-row">
      <div className="user-row-info">
        <p className='row-part role'>{user.role}</p>
        <p className="row-part">{user.full_name_heb}</p>
        <p className="row-part">{user.full_name_eng}</p>
        <p className="row-part username">{user.username}</p>
      </div>
      <div className="edit-user-pen">
        <i
          className="fa-solid fa-pen-to-square edit-pen"
          onClick={() => {
            onUserClick(user);
          }}
        ></i>

        <i
          className="fa-solid fa-trash-can trash-can"
          onClick={() => {
            onDeleteClick(user);
          }}
        ></i>
      </div>
    </div>
  );
};

export default UserRow;

import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { publicUrl } from '../../providers';
import { services } from '../../services';
import Modal from '../modal/Modal';
import FilterGroup from '../tree/FilterGroup';

const EditReport = ({
  title,
  nodeToEdit: { report = null },
  finishCallback,
  toggleModalCallback,
}) => {
  const [img, setImg] = useState(null);
  const [name, setName] = useState('');
  const [nameErr, setNameErr] = useState(false);
  const [url, setUrl] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [urlError, setUrlError] = useState(false);
  const [urlErrorMessage, setUrlErrorMessage] = useState('');
  const [tags, setTags] = useState([]);
  const [searchTags, setSearchTags] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [newTag, setNewTag] = useState('');
  const [deleteImg, setDeleteImg] = useState(false);
  const [deleteTagError, setDeleteTagError] = useState('');
  const [showDeleteTagModal, setShowDeleteTagModal] = useState(false);
  const [tagIdToDelete, setTagIdToDelete] = useState(null);

  const filterdTags = useMemo(() => {
    return tags.filter(
      (tag) => tag.name.includes(searchTags) || searchTags === ''
    );
  }, [tags, searchTags]);

  const fetchTags = async () => {
    const tags = await services.tag.tags();
    setTags(tags);
    setSelectedTags(report.tags);
  };

  const filterTags = (e) => {
    const search = e.target.value;
    setSearchTags(search);
  };

  const updateTags = (tagId, checked) => {
    const newSelectedTags = [...selectedTags];
    if (checked) {
      newSelectedTags.push(tagId);
    } else newSelectedTags.splice(newSelectedTags.indexOf(tagId), 1);
    setSelectedTags(newSelectedTags);
  };

  const deleteTag = async () => {
    const id = tagIdToDelete;
    if (!id) return;

    try {
      const success = await services.tag.deleteTag(id, report.id);
      if (success) {
        const newTags = tags.filter((tag) => tag.id !== id);
        setTags(newTags);
      }
      setShowDeleteTagModal(false);
      toast.success('התג נמחק');
    } catch (err) {
      console.log(err.response.data.message);
      setDeleteTagError('התג משויך לדוח אחר');
      toast.error('שגיאה קרתה');
      setShowDeleteTagModal(false);
    }
  };

  const addTag = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (newTag !== '') {
      const tag = await services.tag.addTag(newTag);
      setTags([...tags, tag]);
      setNewTag('');
    }
  };

  useEffect(() => {
    if (report) {
      setName(report.name);
      setUrl(report.url);
      setDescription(report.description);
      setDate(report.date);
    }
    fetchTags();
  }, [report]);

  const onClickOutside = () => {
    toggleModalCallback(false);
  };

  const itemValid = () => {
    let valid = true;
    if (name.trim().length === 0) {
      setNameErr(true);
      valid = false;
    }
    if (url.trim().length === 0) {
      setUrlError(true);
      setUrlErrorMessage('שדה חובה');
      valid = false;
    }
    return valid;
  };

  const removeImage = () => {
    setImg(null);
    setDeleteImg(true);
  };

  const handleSuccess = async (e) => {
    e.preventDefault();
    setNameErr(false);
    setUrlError(false);
    setUrlErrorMessage('');

    if (!itemValid()) {
      return;
    }

    if (img && !/^image\//.test(img.type)) {
      return;
    }

    if (
      url &&
      !/(http(s)?)([:]{1})(\/\/(www\.)?)[a-zA-Z0-9@:%._\+~#=]{2,256}(\.[a-z]{2,20})?/.test(
        url
      )
    ) {
      setUrlError(url);
      return setUrlErrorMessage('הכנס כתובת URL תקינה');
    }

    try {
      await finishCallback(
        report ? report.id : null,
        name,
        url,
        description,
        date || new Date().toISOString(),
        img,
        selectedTags,
        deleteImg
      );
    } catch (err) {
      setUrlError(true);
      setUrlErrorMessage(err.message);
    }
  };

  return (
    <Modal large title={title} onClose={onClickOutside}>
      <form
        onSubmit={handleSuccess}
        className="add-tree-form"
        style={{ display: 'grid' }}
        onClick={() => setDeleteTagError('')}
      >
        <div>
          <div className="form-group">
            <label htmlFor="img">תמונת תצוגה מקדימה:</label>
            {report && report.img && !deleteImg && (
              <>
                <button
                  type="button"
                  className="x-button"
                  onClick={removeImage}
                >
                  <i className="fa-solid fa-trash-can trash-can"></i>
                </button>
                <img className="tree-icon" src={publicUrl(report.img)} alt="" />
              </>
            )}
            <input
              id="img"
              onChange={(e) => setImg(e.target.files[0])}
              type="file"
              accept="image/*"
            />
          </div>
          <div className="form-group">
            <label htmlFor="name">שם דוח:</label>
            <input
              id="name"
              onChange={(e) => setName(e.target.value)}
              value={name}
              type="text"
              placeholder="שם דוח"
            />
            {nameErr && <p className="form-error">שדה חובה</p>}
          </div>
          <div className="form-group">
            <label htmlFor="url">כתובת הדוח:</label>
            <input
              id="url"
              onChange={(e) => {
                setUrl(e.target.value);
                setUrlError(false);
              }}
              value={url}
              type="text"
              placeholder="כתובת iframe לדוח"
            />
            {urlError && <p className="form-error">{urlErrorMessage}</p>}
          </div>

          <div className="form-group">
            <label htmlFor="description">תיאור הדוח:</label>
            <input
              id="description"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              type="text"
              placeholder="תיאור הדוח"
            />
          </div>

          {/* <div className="form-group">
            <label htmlFor="date">תאריך:</label>
            <div className="date-formatted">
              {moment(date || Date.now()).format('DD/MM/YYYY')}
            </div>
            <input
              id="date"
              onChange={(e) => setDate(e.target.value)}
              value={moment(date || Date.now()).format('YYYY-MM-DD')}
              type="date"
            />
          </div> */}
          {report?.breadcrumbsAdmin?.length ? (
            <div className="form-group">
              <label htmlFor="">נתיבים:</label>
            </div>
          ) : (
            <></>
          )}
          {report?.breadcrumbsAdmin?.length ? (
            <div className="report-breadcrumbs">
              {report.breadcrumbsAdmin?.map((breadcrumbArray, i) => (
                <>
                  <div className="report-breadcrumbs-item">
                    {breadcrumbArray?.map((breadcrumb, i) => (
                      <>
                        <span>{breadcrumb === 0 ? '' : breadcrumb}</span>
                        <span>
                          {breadcrumbArray?.length - 1 !== i && ' > '}
                        </span>
                      </>
                    ))}
                  </div>
                  <div className="report-breadcrumbs-seperator">
                    {report.breadcrumbsAdmin?.length - 1 !== i && '|'}
                  </div>
                </>
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div>
          <FilterGroup
            filterGroups={filterTags}
            selectedGroups={selectedTags}
            updateSelectedGroups={updateTags}
            filterdGroups={filterdTags}
            deleteItem={(id) => {
              setTagIdToDelete(id);
              setShowDeleteTagModal(true);
            }}
            title="תיוגים"
            placeholder="חיפוש תיוגים"
            onClick={() => setDeleteTagError('')}
          />
          {deleteTagError && <p style={{ color: 'red' }}>{deleteTagError}</p>}
          <div>
            <input
              type="text"
              placeholder="הוסף תגית"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
            />
            <button
              style={{ marginBottom: '10px' }}
              className="site-btn"
              onClick={addTag}
            >
              שמור תגית
            </button>
            <button
              className="site-btn"
              type="submit"
              style={{
                float: 'left',
                marginLeft: 0,
              }}
            >
              שמור
            </button>
          </div>
        </div>
      </form>
      {showDeleteTagModal && (
        <Modal
          title={`למחוק תיוג?`}
          onClose={() => setShowDeleteTagModal(false)}
        >
          <div style={{ textAlign: 'center' }}>
            <button className="site-btn" onClick={() => deleteTag()}>
              אישור מחיקה
            </button>
            <button
              onClick={() => setShowDeleteTagModal(false)}
              className="site-btn"
            >
              ביטול
            </button>
          </div>
        </Modal>
      )}
    </Modal>
  );
};

export default EditReport;

import Api from "./Api";

class ReportService {
    async reports(page = 1, limit = 3, query = null) {
        try {
            const {
                data: {
                    data: { reports },
                },
            } = await Api.get("reports", {
                page: page,
                limit: limit,
                query: query,
            });

            return reports;
        } catch (e) {
            throw e;
        }
    }

    async editReport(id, name, url, description, date, img, tags, shouldDeleteImg) {
        await Api.files("PATCH", `reports/${id}`, {
            name,
            url,
            description,
            date,
            img,
            tags: JSON.stringify(tags),
            shouldDeleteImg
        });

        return true;
    }

    async addReport(name, url, description, date, img, selectedTags) {
        try {
            const { data } = await Api.files("POST", `reports`, {
                name,
                url,
                description,
                date,
                img,
                selectedTags: JSON.stringify(selectedTags),
            });

            return data.data.report;
        } catch (e) {
            console.error(e)
            throw e;
        }
    }

    async deleteReport(id) {
        try {
            await Api.delete(`reports/${id}`);

            return true;
        } catch (e) {
            throw e;
        }
    }

    async allReports() {
        try {
            const {
                data: {
                    data: { reports },
                },
            } = await Api.get("reports");

            return reports;
        } catch (e) {
            throw e;
        }
    }
}

export default new ReportService();

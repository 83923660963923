import Api from './Api';

class AuthService {
  get hasToken() {
    return Api.token && Api.token.length > 0;
  }

  setToken(token) {
    Api.token = token;
  }

  removeToken() {
    Api.removeToken();
  }

  logout() {
    Api.removeToken();
  }

  async login(username, password) {
    try {
      const {
        data: {
          data: { token },
        },
      } = await Api.post('auth/login', {
        username,
        password,
      });

      if (token) {
        return token;
      }

      throw new Error();
    } catch (e) {
      throw e;
    }
  }

  async user() {
    try {
      const { data } = await Api.get('auth/user');

      return data;
    } catch (e) {
      return false;
    }
  }
}

export default new AuthService();

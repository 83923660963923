import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo-2.png';
import { services } from '../services';
import openEye from '../assets/openEye.svg';
import Loader from '../components/Loader';
import { toast } from 'react-toastify';

const Login = () => {
  const [eyeOpen, setOpenEye] = useState(false);
  const [username, setUsername] = useState('');
  const [error, setError] = useState(false);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLoginForm = async (e) => {
    if (loading) {
      return;
    }

    e.preventDefault();

    setLoading(true);
    setError(false);

    try {
      const token = await services.auth.login(username, password);

      services.auth.setToken(token);

      setLoading(false);

      navigate('/', { replace: true });
    } catch (e) {
      toast.error('קרתה שגיאה');
      console.log('Invalid login');

      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    if (services.auth.hasToken) {
      navigate('/');
      return;
    }
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="LoginPage">
          <div className="loginContent">
            <div className="login-logo-wrapper">
              <img src={logo} alt="mbi" />
            </div>
            <form className="login-box" onSubmit={handleLoginForm}>
              <p className="login-header">ברוכים הבאים</p>
              <div className="inputs-wrapper">
                <div
                  style={{
                    backgroundColor: username ? 'white' : '#f5f5f5 ',
                  }}
                  className={
                    error
                      ? 'input-wrapper name-wrapper error-input'
                      : 'input-wrapper name-wrapper'
                  }
                >
                  <input
                    type="name"
                    className="login-input"
                    placeholder="שם משתמש"
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                  />
                </div>
                <div
                  style={{
                    backgroundColor: password ? 'white' : '#f5f5f5 ',
                  }}
                  className={
                    error
                      ? 'input-wrapper password-wrapper error-input'
                      : 'input-wrapper password-wrapper'
                  }
                >
                  <input
                    type={eyeOpen ? 'text' : 'password'}
                    className="login-input"
                    placeholder="סיסמא"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  <img
                    onClick={() => {
                      setOpenEye(!eyeOpen);
                    }}
                    className={eyeOpen ? 'openEye' : 'closeEye'}
                    src={openEye}
                    alt=""
                  />
                </div>
              </div>
              {error ? <h4>פרטי התחברות שגויים</h4> : ''}
              <button type="submit" className="login-btn">
                להתחבר
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;

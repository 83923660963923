import React from 'react';
import UserProvider from './UserProvider';

export const AppProviders = ({ children }) => {
  return <UserProvider>{children}</UserProvider>;
};

export const TreeAndReportsProvider = ({ children }) => {
  return <TreeAndReportsProvider>{children}</TreeAndReportsProvider>;
};

export const publicUrl = (path) => {
  return `${process.env.REACT_APP_PUBLIC_URL}${path}`;
};
//export default AppProviders;

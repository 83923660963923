import Modal from '../modal/Modal';

const ConfirmDeleteUser = ({ user, setShowDeleteBox, deleteUser }) => {
  const onClickOutside = () => {
    setShowDeleteBox(false);
  };

  const handleDeleteItem = () => {
    deleteUser(user);
  };

  return (
    <Modal
      title={`אישור מחיקת משתמש - ${user.username}`}
      onClose={onClickOutside}
    >
      <div style={{ textAlign: 'center' }}>
        <button onClick={handleDeleteItem} className="site-btn">
          אישור מחיקה
        </button>
        <button onClick={onClickOutside} className="site-btn">
          ביטול
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteUser;

import Api from './Api';

class UserService {
  async allUsers() {
    try {
      const {
        data: {
          data: { users },
        },
      } = await Api.get('users');

      return users;
    } catch (e) {
      throw e;
    }
  }

  async deleteUser(id) {
    try {
      await Api.delete(`users/${id}`);

      return true;
    } catch (e) {
      throw e;
    }
  }

  async updateUser(id, updateData) {
    try {
      await Api.patch(`users/${id}`, updateData);

      return true;
    } catch (e) {
      throw e;
    }
  }
}

export default new UserService();

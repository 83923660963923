import Api from "./Api";

class TreeService {
    async updateTree(tree) {
        try {
            await Api.patch("tree", {
                tree: tree,
            });

            return true;
        } catch (e) {
            throw e;
        }
    }

    async addTreeItem(title, slug, order, parentId, level) {
        try {
            const {
                data: {
                    data: { tree },
                },
            } = await Api.post(`tree`, { title, slug, order, parentId, level });

            return tree;
        } catch (e) {
            throw e;
        }
    }

    async editTreeItem(
        id,
        title,
        slug,
        isLast,
        selectedReports,
        icon,
        selectedGroups,
        children
    ) {
        try {
            const {
                data: {
                    data: { tree },
                },
            } = await Api.files("PUT", `tree/${id}`, {
                title,
                slug,
                is_last: isLast || 0,
                reports: JSON.stringify(selectedReports),
                icon,
                groups: JSON.stringify(selectedGroups),
                children: JSON.stringify(children),
            });
            tree.groups = tree.groups;
            return tree;
        } catch (e) {
            throw e;
        }
    }

    async deleteTreeItem(id) {
        try {
            await Api.delete(`tree/${id}`);

            return true;
        } catch (e) {
            throw e;
        }
    }

    async addReportToTree(nodeId, reportId) {
        try {
            await Api.put(`tree/${nodeId}/${reportId}`);
            return true;
        } catch (err) {
            throw err;
        }
    }

    async tree() {
        try {
            const {
                data: {
                    data: { tree },
                },
            } = await Api.get("tree");

            return tree;
        } catch (e) {
            return false;
        }
    }
}

export default new TreeService();

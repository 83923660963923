import Api from './Api';

class GroupService {
	async groups() {
		try {
			const {
				data: {
					data: { groups },
				},
			} = await Api.get('groups');

			return groups;
		} catch (e) {
			return false;
		}
	}
}

export default new GroupService();

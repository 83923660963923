import { useState } from 'react';
import Modal from '../modal/Modal';

const AddTreeItem = ({ addTreeItem, setShowAddBox }) => {
	const [title, setTitle] = useState('');
	const [titleErr, setTitleErr] = useState(false);
	const [slug, setSlug] = useState('');
	const [error, setError] = useState(false);
	const [errMessage, setErrMessage] = useState('');

	const onClickOutside = () => {
		setShowAddBox(false);
	};

	const itemValid = () => {
		let valid = true;
		if (title.trim().length === 0) {
			setTitleErr(true);
			valid = false;
		}

		if (slug.trim().length === 0) {
			setError(true);
			setErrMessage('שדה חובה');
			valid = false;
		} else {
			const regexH = new RegExp(/[\u0590-\u05FF]/);
			const regexNumbers = new RegExp(/^([^0-9]*)$/);
			if (regexH.test(slug) || !regexNumbers.test(slug)) {
				setError(true);
				setErrMessage('יש להזין תווים באנגלית בלבד ללא מספרים');
				valid = false;
			}
		}
		return valid;
	};

	const handleAddTreeItem = async (e) => {
		e.preventDefault();
		setErrMessage('');
		setTitleErr(false);
		if (!itemValid()) {
			return;
		}

		const added = await addTreeItem(title, slug);
		
		setError(true);
		setErrMessage(added);
	};

	return (
		<Modal title={'הוספת רמה חדשה'} onClose={onClickOutside}>
			<form onSubmit={handleAddTreeItem} className="add-tree-form">
				<div className="form-group">
					<label htmlFor="title">כותרת רמה:</label>
					<input
						id="title"
						onChange={(e) => setTitle(e.target.value)}
						type="text"
						placeholder="כותרת רמה"
					/>
					{titleErr && <p className="form-error">שדה חובה</p>}
				</div>
				<div className="form-group">
					<label htmlFor="slug">מזהה יחודי:</label>
					<input
						id="slug"
						onChange={(e) => setSlug(e.target.value)}
						type="text"
						placeholder="מזהה יחודי לרמה"
					/>
					{error && <p className="form-error">{errMessage}</p>}
				</div>

				<div>
					<button className="site-btn" type="submit">
						הוספה
					</button>
				</div>
			</form>
		</Modal>
	);
};

export default AddTreeItem;

import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { isReportInTree } from '../utils';
import { services } from '../services';

const EditReportRow = ({ report, clickOnBox, index, clickToDelete, tree }) => {
  const inTree = useMemo(() => {
    if (!tree || tree.length === 0) return false;
    let inTree = false;
    for (let child of tree) {
      inTree = inTree || isReportInTree(child, report.id);
    }
    return inTree;
  }, [tree]);

  return (
    <div className="edit-report-row">
      <div className="row-id-wrapper">{report.id}</div>
      <div className="row-edit-text row-edit-title">{report.name}</div>
      <div className="row-edit-text iframe-url">{report.url}</div>
      <div className="row-edit-text related">{inTree ? 'כן' : 'לא'}</div>
      <div className="row-edit-text createdBy">
        <div>{report?.createdBy}</div>
      </div>
      <div className="row-edit-text modifiedBy">
        <div>{report?.modifiedBy}</div>
      </div>

      <div className="row-edit-text date">
        {report.date && moment(report.date).format('DD/MM/YYYY')}
      </div>
      <div style={{ width: 60 }}>
        <i
          className="fa-solid fa-pen-to-square edit-pen"
          onClick={() => {
            clickOnBox(report, index);
          }}
        ></i>

        <i
          className="fa-solid fa-trash-can trash-can"
          onClick={() => {
            clickToDelete(report);
          }}
        ></i>
      </div>
    </div>
  );
};

export default EditReportRow;

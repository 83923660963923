import React from 'react';
import ichilovLogo from '../assets/images/ichilov-logo.webp';
import mbi from '../assets/images/logo-2.png';
import { Link, NavLink } from 'react-router-dom';
import { useUser } from '../providers/UserProvider';
import { useEffect } from 'react';

const Header = () => {
  const { logout } = useUser();
  const { user } = useUser();

  const handleLogout = (e) => {
    e.preventDefault();
    logout();
  };

  return (
    <div className="header">
      <div className="header-right">
        <div className="header-img-wrapper">
          <NavLink to="/">
            <img src={mbi} />
          </NavLink>
        </div>
      </div>
      <div className="header-left">
        <div style={{ fontWeight: 300 }} className={'header-link'}>
          {user?.user?.full_name_heb}
        </div>
        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive
              ? 'lactive-class header-link'
              : 'not-active-class header-link'
          }
        >
          עץ אתר
        </NavLink>
        <NavLink
          to="/site-reports"
          className={({ isActive }) =>
            isActive
              ? 'lactive-class header-link'
              : 'not-active-class header-link'
          }
        >
          דוחות
        </NavLink>
        <NavLink
          to="/site-users"
          className={({ isActive }) =>
            isActive
              ? 'lactive-class header-link'
              : 'not-active-class header-link'
          }
        >
          משתמשים
        </NavLink>

        <NavLink
          className={'not-active-class header-link'}
          to="#"
          onClick={(e) => handleLogout(e)}
        >
          התנתקות
        </NavLink>
      </div>
    </div>
  );
};

export default Header;

import { useEffect, useRef } from 'react';
import close from '../../assets/images/close.svg';

const Modal = ({ title, onClickOutside, onClose, children, large }) => {
	const ref = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				onClickOutside && onClickOutside();
			}
		};
		document.addEventListener('mousedown', handleClickOutside, true);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside, true);
		};
	}, [onClickOutside]);

	return (
		<div className="site--modal">
			<div
				ref={ref}
				className="site--modal--body"
				style={large && { maxWidth: '80vw' }}
			>
				<div className="site--modal--header">
					<h3>{title}</h3>
					{onClickOutside && 	
						<span
							onClick={onClickOutside }
							className="site--modal--close"
						>
							<img src={close} alt="Close" />
						</span>
					}
					{onClose &&
						<span
							onClick={onClose}
							className="site--modal--close"
						>
							<img src={close} alt="Close" />
						</span>
					}
				</div>
				<div className="site--modal--inner">{children}</div>
			</div>
		</div>
	);
};

export default Modal;
